import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";
import { Section } from "../components/section/Section";

const StyledP = styled.p`
  font-size: 20px;
  text-align: center;
`;

const ContactsSuccessPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Contatti</title>
    </Helmet>
    <Intro title="Messaggio inviato correttamente"></Intro>
    <Section>
      <StyledP>Grazie per averci contattato.</StyledP>
      <StyledP>
        <Link to="/">Torna alla homepage</Link>
      </StyledP>
    </Section>
  </Layout>
);

export default ContactsSuccessPage;
